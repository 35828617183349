import React, { useState } from 'react';
import styled from 'styled-components';


// Navbar Container
const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 40px;
  background-color: white;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000; /* Ensures the navbar is above other content */
  /* Optional: Add a box shadow for a subtle 3D effect */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
`;

// Navbar Links
const NavLinks = styled.div`
  display: flex;
  gap: 1.5rem;

  @media (max-width: 1024px) {
    display: none;
  }
`;

// Navbar Link
const NavLink = styled.a`
  color: ${props => (props.active ? 'blue' : '#757575')};
  text-decoration: none;
  font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 120%; /* 19.2px */
letter-spacing: -0.32px;

`;

// Hamburger Icon
const HamburgerIcon = styled.div`
  display: none;
  flex-direction: column;
  cursor: pointer;
  gap: 0.2rem;

  @media (max-width: 768px) {
    display: flex;
  }

  div {
    width: 25px;
    height: 3px;
    background-color: #1E1E1E;
  }
`;

// Mobile Menu
const MobileMenu = styled.div`
  display: none;
  flex-direction: column;
  background-color: #333;
  position: fixed;
  top: 60px;
  right: 0;
  width: 100%;
  text-align: center;
  z-index:10;

  @media (max-width: 768px) {
    display: flex;
  }

  a {
    color: white;
    padding: 1rem;
    text-decoration: none;
    display: block;
    &:hover {
      background-color: #444;
    }
  }
`;


const LogoDiv = styled.div`
  font-family: Expletus Sans;
  color: #562BD4;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.64px;
`

function Navbar() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [activeLink, setActiveLink] = useState('#header');

    const handleLinkClick = (link) => {
        setActiveLink(link);
        setIsMenuOpen(false); // Optionally close the menu on click for mobile view
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <div>
            <NavbarContainer>
                <LogoDiv>adventra</LogoDiv>
                <NavLinks>
                    <NavLink href="#header" active={activeLink === '#header'}
                        onClick={() => handleLinkClick('#header')}
                    >Malnad Ultra</NavLink>
                    <NavLink href="#programschedule" active={activeLink === '#programschedule'}
                        onClick={() => handleLinkClick('#programschedule')}
                    >Package Itinerary</NavLink>

                    <NavLink href="#accomodation" active={activeLink === '#accomodation'} onClick={() => handleLinkClick('#accomodation')}>Accommodation</NavLink>
                    <NavLink href="#pricing" active={activeLink === '#pricing'} onClick={() => handleLinkClick('#pricing')}>Package Pricing</NavLink>
                    {/* <NavLink href="#bookingform" active={activeLink === '#bookingform'} onClick={() => handleLinkClick('#bookingform')}>Booking Form </NavLink> */}
                    <NavLink href="#aboutus" active={activeLink === '#aboutus'} onClick={() => handleLinkClick('#aboutus')}>About Us</NavLink>

                </NavLinks>
                <HamburgerIcon onClick={toggleMenu}>
                    <div></div>
                    <div></div>
                    <div></div>
                </HamburgerIcon>
            </NavbarContainer>

            
            {isMenuOpen && (
                <MobileMenu>
                    <NavLink href="#header" onClick={() => setIsMenuOpen(!isMenuOpen)} >Malnad Ultra</NavLink>
                    <NavLink href="#i1" onClick={() => setIsMenuOpen(!isMenuOpen)} >Package Itinerary</NavLink>
                    <NavLink href="#accomodation1" onClick={() => setIsMenuOpen(!isMenuOpen)} >Accommodation</NavLink>
                    <NavLink href="#pricing" onClick={() => setIsMenuOpen(!isMenuOpen)} >Package Pricing</NavLink>
                    <NavLink href="#bookingform" onClick={() => setIsMenuOpen(!isMenuOpen)} >Booking Form </NavLink>
                    <NavLink href="#aboutus" onClick={() => setIsMenuOpen(!isMenuOpen)} >About Us</NavLink>
                </MobileMenu>
            )}
        </div>
    );
}

export default Navbar;