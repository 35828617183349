import ResponsiveContainer from "../components/Cards/ResponsiveContainer";
import ImageGallery from "../components/ImageContainer/ImageGallery";
import Text from "../components/TextSection/Text";
import image1 from '../assets/images/Image13.jpg'
import image2 from '../assets/images/Image14.jpg'
import image3 from '../assets/images/image3.png'
import image4 from '../assets/images/Image4.png'
import ScreenshotMap1 from '../assets/images/ScreenshotMap4.png'
import styled from "styled-components";

const PackageSection = () => {

    const StyledListItem = styled.li`
  font-size: 20px;

  /* Media query for larger screens (web) */
  @media (max-width: 432px) {
    font-size: 15px !important;
  }
`;
    return (
        <>
            <div style={{ marginLeft: '2%', marginRight: '2%', marginTop: 60 }} >
                <ResponsiveContainer
                    headingText="Package"
                    // additionalText="This is some optional additional text."
                    bgColor="#f0f0f0"
                    textAlign="centre"
                    fontWeight={700}
                    fontSize={42}
                >
                    <div style={{ display: 'flex', justifyContent: 'left' }}>
                        <div
                            style={{
                                width: '1000px',
                                textAlign: 'left', // Align text inside the div to the left
                                padding: '20px', // Optional: Add padding for better visual
                            }}
                        >
                            <ol style={{paddingBottom:0,marginBottom:0}}>
                                <StyledListItem ><Text
                                    fontWeight={400}
                                    fontSize={20}
                                    fontFamily="Inter"
                                    color="#1E1E1E"
                                >2 night, 3 days stay at Navile (See below for room details)
                                </Text></StyledListItem>
                                <StyledListItem style={{fontSize:20}}><Text
                                    fontWeight={400}
                                    fontSize={20}
                                    fontFamily="Inter"
                                    color="#1E1E1E"
                                >Breakfast, Lunch, Hi-Tea & Snacks, Dinner (Menu as per Malnad Ultra team recommendations)
                                </Text></StyledListItem>

                                <StyledListItem style={{fontSize:20}} ><Text
                                    fontWeight={400}
                                    fontSize={20}
                                    fontFamily="Inter"
                                    color="#1E1E1E"
                                >T-shirt
                                </Text></StyledListItem>
                            </ol>
                        </div>
                    </div>
                    <div id="accomodation"  style={{ display: 'flex', justifyContent: 'left',marginLeft:'2%',paddingBottom:10 }}>
                        <Text
                            fontWeight={700}
                            fontSize={20}
                            fontFamily="Inter"
                            color="#1E1E1E"
                        >* Transport from/to Bangalore for the run can be arranged at additional cost for a group of 6 or more.
                        </Text>
                    </div>
                    <span id="accomodation1"></span>
                    
                </ResponsiveContainer>

            </div>



            <div style={{ marginTop: 50}} >
                <Text
                    fontWeight={700}
                    fontSize={48}
                    fontFamily="Inter"
                    color="#1E1E1E"
                >
                    Accommodation
                </Text>
            </div>

           

       
                <div  style={{marginLeft:'1.5%',marginRight:'2%'}}>
                    <ImageGallery
                        leftImageSrc={ScreenshotMap1}
                        rightImageSrcs={[
                            image1,
                            image2,
                            image3,
                            // image4
                        ]}
                    />
                </div>
           

        </>
    )
}

export default PackageSection;