import React from 'react';
import styled from 'styled-components';
import Text from '../TextSection/Text';

// Styled component for the container
const Container = styled.div`
  background-color: ${({ bgColor }) => bgColor || '#ffffff'};
  padding: 10px;
  border-radius: 8px;
  text-align: ${({ textAlign }) => textAlign || 'left'};
  max-width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  border: 0.5px solid #f0f0f0;

  @media (max-width: 768px) {
    padding: 15px;
  }

  @media (max-width: 480px) {
    padding: 10px;
  }
`;

// Styled component for the heading
const Heading = styled.h1`
  font-size: 24px;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 20px;
  }

  @media (max-width: 480px) {
    font-size: 18px;
  }
`;

// Styled component for the additional text
const AdditionalText = styled.p`
  font-size: 16px;
  color: #333;

  @media (max-width: 768px) {
    font-size: 14px;
  }

  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

const ResponsiveContainer = ({
    headingText,
    additionalText,
    bgColor,
    textAlign,
    children,
    fontWeight,
    fontSize,
}) => {
    return (
        <Container bgColor={bgColor} textAlign={textAlign}>
            
            <Text
                fontWeight={fontWeight}
                fontSize={fontSize}
                fontFamily="Inter"
                color="#1E1E1E"
            >
                {headingText}
            </Text>
            
            {additionalText && <AdditionalText>{additionalText}</AdditionalText>}
            {children}
        </Container>
    );
};

export default ResponsiveContainer;
