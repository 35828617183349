import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import ImageModal from '../Modals/ImageModal';

// Styling for images
const imageStyle = {
  width: '100%',
  height: 'auto', // Adjust height to maintain aspect ratio
  borderRadius: '8px',
  marginBottom:'4%'
};

// Container for grid images to ensure equal dimensions
const gridImageContainerStyle = {
  position: 'relative',
  paddingBottom: '56.25%', // 16:9 Aspect Ratio
  height: 0,
  overflow: 'hidden',
  borderRadius: '8px',
  marginBottom: '1.2rem', // Optional: Margin between images
};

// Image within the grid container
const gridImageInnerStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover', // Ensure image covers container without distortion
};

const ImageGallery = ({ leftImageSrc, rightImageSrcs }) => {
  const [modal, setModal] = useState(false);

  return (
    <>
      <Row>
        <Col md="6" xs="12" className="d-flex"  onClick={() => {
          window.open('https://www.google.com/maps/place/Navile+Homestay+Chikkmangalore/@13.36175,75.670463,1063m/data=!3m2!1e3!4b1!4m6!3m5!1s0x3bbb27b033806b97:0xa9ecb383327b93f3!8m2!3d13.36175!4d75.670463!16s%2Fg%2F11rrv0878d?entry=ttu&g_ep=EgoyMDI0MDkwMy4wIKXMDSoASAFQAw%3D%3D');
        }}>
          <img src={leftImageSrc} alt="Left Image" style={imageStyle} />
        </Col>
        <Col md="6" xs="12">
          <Row>
            {rightImageSrcs.map((src, index) => (
              <Col md="6" xs="12" key={index} className="mb-3" onClick={() => setModal(true)}>
                <div style={gridImageContainerStyle}>
                  <img src={src} alt={`Right Image ${index + 1}`} style={gridImageInnerStyle} />
                </div>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
      <ImageModal modal={modal} setModal={setModal} images={rightImageSrcs} />
    </>
  );
};

// Define PropTypes for the component
ImageGallery.propTypes = {
  leftImageSrc: PropTypes.string.isRequired,
  rightImageSrcs: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ImageGallery;
