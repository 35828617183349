import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Table } from 'reactstrap';
import styled from 'styled-components';
import Carousel2 from '../Carousels/Carosel2';
import './ImageModal.css'

function ImageModal(props) {


    const toggle = () => props.setModal(false);

    const tableData = [
        {
            size: 'XS',
            chestSize: '31.5',
            ShoulderSize: '14.5'
        },
        {
            size: 'S',
            chestSize: '34',
            ShoulderSize: '15'
        },
        {
            size: 'M',
            chestSize: '46',
            ShoulderSize: '15.5'
        },
        {
            size: 'L',
            chestSize: '48',
            ShoulderSize: '15.8'
        },
        {
            size: 'XL',
            chestSize: '41',
            ShoulderSize: '16'
        },
        {
            size: '2XL',
            chestSize: '43',
            ShoulderSize: '16.4'
        }
    ]


    const CenteredTd = styled.td`
  text-align: center;
`;
    const CenteredTh = styled.th`
text-align: center;
`;

const images = [
    'https://via.placeholder.com/800x600',
    'https://via.placeholder.com/800x600/ff0000',
    'https://via.placeholder.com/800x600/00ff00',
    'https://via.placeholder.com/800x600/0000ff'
  ];

    return (
        <div>

            <Modal isOpen={props.modal} toggle={toggle} {...props}  size='lg'>
                <ModalHeader toggle={toggle}></ModalHeader>
                <ModalBody>
                    <div>                       
                        <Carousel2 images={props.images} />
                    </div>
                </ModalBody>

            </Modal>
        </div>
    );
}

export default ImageModal;