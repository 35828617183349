import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Styled container to handle the aspect ratio with given width and height
const ImageWrapper = styled.div`
  position: relative;
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || 'auto'};
  background-color: #f0f0f0; /* Optional: background color while image loads */
  overflow: hidden; /* Ensures image stays within the container */
`;

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Maintain aspect ratio and cover container */
`;

const ImageContainer = ({ width, height, imageSrc }) => {
  return (
    <ImageWrapper width={width} height={height}>
      <Image src={imageSrc} alt="Responsive" />
    </ImageWrapper>
  );
};

// Define PropTypes for the component
ImageContainer.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  imageSrc: PropTypes.string.isRequired,
};

export default ImageContainer;
