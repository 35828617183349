import React from 'react'
import Navbar from '../../components/Navbar/Navbar';
import HeaderSection from "../../sections/HeaderSection";
import RaceSection from "../../sections/RaceSection";
import ProgramSection from "../../sections/ProgramSection";
import PackageSection from "../../sections/PackageSection";
import RoomsSection from "../../sections/RoomsSection";
import PriceSection from "../../sections/PriceSection";
import CaroselSection from "../../sections/CaroselSection";
import FooterSection from "../../sections/FooterSection";
import styled from "styled-components";

function Home() {

    const StyledDiv = styled.div`
  padding-top: 80px;

  @media (max-width: 432px) { /* Adjust according to your needs */
    padding-top:60px; /* Example for smaller screens */
  }
`;

    return (
        <div style={{textAlign: 'center'}}>
            <Navbar />
            <div id='header'></div>
            <StyledDiv>
                <HeaderSection />
                <CaroselSection />
                <RaceSection />
                <ProgramSection />
                <div id="programschedule">
                    <PackageSection />
                </div>
                <RoomsSection />
                <PriceSection />
                {/* <div style={{ marginTop: 30 }} >
          <Text
            fontWeight={700}
            fontSize={48}
            fontFamily="Inter"
            color="#000000"
          >Booking Form
          </Text>
        </div> */}
                {/* <div>
          <BookingForm />
        </div> */}

                <div id={'aboutus'}>
                    <FooterSection />
                </div>
            </StyledDiv>
        </div>
    )
}

export default Home
