import './App.css';
import Navbar from './components/Navbar/Navbar';
import Text from './components/TextSection/Text';
import BookingForm from "./components/Forms/BookingForm";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HeaderSection from "./sections/HeaderSection";
import RaceSection from "./sections/RaceSection";
import ProgramSection from "./sections/ProgramSection";
import PackageSection from "./sections/PackageSection";
import RoomsSection from "./sections/RoomsSection";
import PriceSection from "./sections/PriceSection";
import CaroselSection from "./sections/CaroselSection";
import FooterSection from "./sections/FooterSection";
import styled from "styled-components";
import Home from './pages/home/Home';
import TermAndCondition from './pages/termAndCondition/TermAndCondition';
import PrivacyAndPolicy from './pages/privacyPolicy/PrivacyAndPolicy';
import Disclaimer from './pages/disclaimer/Disclaimer';
import TravelProvider from './pages/travelProvider/TravelProvider';
import RefundAndCancellation from './pages/refundAndCancellation/RefundAndCancellation';


function App() {
  const imageUrls = [
    'https://via.placeholder.com/800x300?text=Slide+1',
    'https://via.placeholder.com/800x300?text=Slide+2',
    'https://via.placeholder.com/800x300?text=Slide+3',
  ];
  const StyledDiv = styled.div`
  padding-top: 80px;

  @media (max-width: 432px) { /* Adjust according to your needs */
    padding-top:60px; /* Example for smaller screens */
  }
`;

  return (
    // <div className='App'>
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route exact path="/TermsAndConditions" element={<TermAndCondition />} />
        <Route exact path="/PrivacyPolicy" element={<PrivacyAndPolicy />} />
        <Route exact path="/Disclaimer" element={<Disclaimer />} />
        <Route exact path="/TravelProviderAgreement" element={<TravelProvider />} />
        <Route exact path="/RefundsAndCancellationPolicy" element={<RefundAndCancellation />} />
      </Routes>
    </Router>
    // </div>
  );
}

export default App;



// https://malnadultra.com/
// Shashank Johri
// 09:11
// https://www.google.com/maps/place/Navile+Homestay+Chikkmangalore/@13.36175,75.670463,1063m/data=!3m2!1e3!4b1!4m6!3m5!1s0x3bbb27b033806b97:0xa9ecb383327b93f3!8m2!3d13.36175!4d75.670463!16s%2Fg%2F11rrv0878d?entry=ttu&g_ep=EgoyMDI0MDkwMy4wIKXMDSoASAFQAw%3D%3D