import React from 'react';
import Slider from 'react-slick';
import './Carousels.css'; // Import custom styles

function Carousel({ 
  imageUrls = [], 
  width = '100%', 
  height = 'auto', 
  alignment = 'center',
  dots = true, 
  arrows = true, 
  autoplay = true,
  autoplaySpeed = 3000
}) {
  const sliderSettings = {
    dots: dots,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: arrows,
    centerMode: alignment === 'center', // Center mode based on alignment prop
    centerPadding: '0', // Remove extra padding to center correctly
    autoplay: autoplay,
    autoplaySpeed: autoplaySpeed,
  };

  return (
    <div className="carousel-wrapper">
      <div className="carousel-container" style={{ width: width, height: height }}>
        <Slider {...sliderSettings}>
          {imageUrls.map((url, index) => (
            <div key={index} className="carousel-slide">
              <img src={url} alt={`slide-${index}`} />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default Carousel;
