import styled from "styled-components";
import ResponsiveDiv from "../components/Cards/ResponsiveDiv";
import TableComponent from "../components/Tables/TableComponent";
import Text from "../components/TextSection/Text";

const PriceSection = () => {
  const headings = ["", "For groups less than 6", "Group of 6 or more"];
  const data = [["Oct 16th -    Nov 15th", "INR 9,500", "Row 3 Cell 3"]];

  const lastColumnData = [
    ["INR 9,500", "INR 9,000"],
    ["INR 10,500", "INR 10,000"],
  ];

  const Container = styled.div`
    margin-left: 24%;
    display: "flex";
    flex-direction: "column";

    @media (max-width: 432px) {
      margin-left: 12%;
    }
  `;
  return (
    <>
      <div style={{ marginTop: 30 }}>
        <Text fontWeight={700} fontSize={48} fontFamily="Inter" color="#1E1E1E">
          Package Pricing
        </Text>
      </div>

      <div style={{ marginTop: 10, marginBottom: 10 }}>
        <ResponsiveDiv
          textAlign="center"
          maxWidth="90%"
          directionOnSmall="column"
        >
          <ResponsiveDiv
            maxWidth="90%"
            directionOnSmall="column"
            margin='10px' 
          >
            <Container>
              <Text
                fontWeight={400}
                fontSize={22}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                Package pricing per person all inclusive{" "}
                <span style={{ fontWeight: 600 }}>INR 9,500</span>.
              </Text>
            </Container>
          </ResponsiveDiv>
        </ResponsiveDiv>
      </div>

      {/* <TableComponent
                headings={headings}
                data={data}
                fontSize="20px"
                fontStyle="Inter"
                fontWeight="400"
                fontFamily="Inter"
                width="80%"
                height="auto"
                margin="20px auto"
                padding="10px"
                maxWidth="990px"
                headingMaxWidth="150px"
                lastColumnData={lastColumnData}
                lastColumnLineThroughColor="#757575"
                spacingBetweenTexts="10px"
            /> */}

      <ResponsiveDiv
          textAlign="center"
          maxWidth="90%"
          directionOnSmall="column"
      >
        <ResponsiveDiv
          maxWidth="90%"
          directionOnSmall="column"
        >
          <Container>
            <ul>
              <li>
                {" "}
                <Text
                  fontWeight={400}
                  fontSize={20}
                  fontFamily="Inter"
                  color="#757575"
                >
                  Check-In Time - 11 AM, Nov 22, 2024
                </Text>
              </li>

              <li>
                {" "}
                <Text
                  fontWeight={400}
                  fontSize={20}
                  fontFamily="Inter"
                  color="#757575"
                >
                  Check-Out Time - 1 PM, Nov 24, 2024
                </Text>
              </li>

              <li>
                {" "}
                <Text
                  fontWeight={400}
                  fontSize={20}
                  fontFamily="Inter"
                  color="#757575"
                >
                  Payment Schedule - Full Payment on Booking
                </Text>
              </li>
            </ul>
          </Container>
        </ResponsiveDiv>
      </ResponsiveDiv>
      <ResponsiveDiv
        textAlign="center"
        maxWidth="90%"
        directionOnSmall="column"
      >
        <ResponsiveDiv
          justifyContent="center"
          margin="10px"
          maxWidth="90%"
          directionOnSmall="column"
        >
          <Container>
            <Text
              fontWeight={600}
              fontSize={24}
              fontFamily="Inter"
              color="#1E1E1E"
            >
              Terms and Conditions
            </Text>
            <ol style={{ fontSize: 20, marginTop: 20 }}>
              <li>
                {" "}
                <Text
                  fontWeight={400}
                  fontSize={20}
                  fontFamily="Inter"
                  color="#757575"
                >
                  {" "}
                  Room and bed allotment will be determined based on the size of
                  the group and will be at the sole discretion of the company.
                  We strive to ensure comfort for all participants, but specific
                  arrangements may vary to best accommodate group sizes and
                  availability
                </Text>
              </li>
            </ol>
          </Container>
        </ResponsiveDiv>
      </ResponsiveDiv>

      <ResponsiveDiv
        textAlign="center"
        maxWidth="90%"
        directionOnSmall="column"
      >
        <ResponsiveDiv margin="10px" maxWidth="90%" directionOnSmall="column">
          <Container>
            <Text
              fontWeight={600}
              fontSize={24}
              fontFamily="Inter"
              color="#1E1E1E"
            >
              Cancellation Policy
            </Text>
            <ol style={{ fontSize: 20, marginTop: 20 }}>
              <li>
                {" "}
                <Text
                  fontWeight={400}
                  fontSize={20}
                  fontFamily="Inter"
                  color="#757575"
                >
                  100% refund up to October 15th
                </Text>
              </li>

              <li>
                {" "}
                <Text
                  fontWeight={400}
                  fontSize={20}
                  fontFamily="Inter"
                  color="#757575"
                >
                  50% refund up to October 31st
                </Text>
              </li>

              <li id={"bookingform"}>
                {" "}
                <Text
                  fontWeight={400}
                  fontSize={20}
                  fontFamily="Inter"
                  color="#757575"
                >
                  No refund on or after November 1st
                </Text>
              </li>
            </ol>
          </Container>
        </ResponsiveDiv>
      </ResponsiveDiv>
    </>
  );
};

export default PriceSection;
