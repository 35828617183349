import { Col, Row } from "reactstrap"
import Text from "../components/TextSection/Text"
import StyledCardImage from "../components/Cards/StyledCardImage"
import ResponsiveDiv from "../components/Cards/ResponsiveDiv"
import ImageContainer from "../components/ImageContainer/ImageContainer"
import available from '../assets/images/available.png'
import bookedFemale from '../assets/images/bookedFemale.png'
import bookedMale from '../assets/images/bookedMale.png'
import samudra from '../assets/images/samudra.png'
import pawan from '../assets/images/pawan.png'
import bhumi from '../assets/images/bhumi.png'

const RoomsSection = () => {

    const scrollToSection = () => {
        const element = document.getElementById('bookingform');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };


    return (
        <>
            <div style={{ textAlign: 'left', marginLeft: 33, marginRight: 33, marginTop: 20 }}>
                <Text
                    fontWeight={600}
                    fontSize={24}
                    fontFamily="Inter"
                    color="#1E1E1E"
                // style={{ textTransform: 'uppercase' }} // Pass additional styles if needed
                >
                    Room configuration
                </Text>
            </div>
            <div style={{ marginLeft: 33, marginRight: 33, marginTop: '0%' }}>
                <Row>
                    <Col md={4}>
                        <StyledCardImage
                            imageSrc={samudra}
                            headingText="Room 1 (Samudra)"
                            subHeadingText="8 people (4 double beds, sharing basis)"
                            headingSize="20px"
                            headingColor="#1E1E1E"
                            headingWeight="700"
                            headingFontFamily="Inter"
                            // cardWidth="500px"
                            // cardHeight="400px"
                            imageHeight="252px"
                        />
                    </Col>
                    <Col md={4}>
                        <StyledCardImage
                            imageSrc={pawan}
                            headingText="Room 2 (Pawan)"
                            subHeadingText="6 people (3 double beds)"
                            headingSize="20px"
                            headingColor="#1E1E1E"
                            headingWeight="700"
                            headingFontFamily="Inter"
                            // cardWidth="500px"
                            // cardHeight="400px"
                            imageHeight="252px"
                        />
                    </Col>
                    <Col md={4}>
                        <StyledCardImage
                            imageSrc={bhumi}
                            headingText="Room 3  with Mezzanine (Bhumi)"
                            subHeadingText="5 people (2 double beds + 1 single bed)"
                            headingSize="20px"
                            headingColor="#1E1E1E"
                            headingWeight="700"
                            headingFontFamily="Inter"
                            // cardWidth="500px"
                            // cardHeight="400px"
                            imageHeight="252px"
                        />
                    </Col>
                </Row>
            </div>

            <div style={{ textAlign: 'left', marginLeft: '2%', marginRight: '2%', marginTop: '1%' }}>
                <Text
                    fontWeight={600}
                    fontSize={24}
                    fontFamily="Inter"
                    color="#1E1E1E"
                // style={{ textTransform: 'uppercase' }} // Pass additional styles if needed
                >
                    Room and Beds Availability *
                </Text>

            </div>

            <ResponsiveDiv
                justifyContent="center"
                // alignItems="center"
                textAlign="center"
                // padding="20px"
                // margin="10px"
                // width="100%"
                // height="300px"
                maxWidth="1200px"
                directionOnSmall="column"
            >
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', maxWidth: '100%',alignItems:'baseline' }}>
                    <ResponsiveDiv
                        justifyContent="center"
                        alignItems="center"
                        textAlign="center"
                        // padding="20px"
                        margin="10px"
                        width="172px"
                        height="73px"
                        // maxWidth="1200px"
                        directionOnSmall="row"
                    >
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <ImageContainer
                                width="83px"      // Set the width of the container
                                height="43px"     // Set the height of the container
                                imageSrc={available} // Image source
                            />
                            <Text
                                fontWeight={600}
                                fontSize={16}
                                fontFamily="Inter"
                                color="#1E1E1E"
                            // style={{ textTransform: 'uppercase' }} // Pass additional styles if needed
                            >
                                Available
                            </Text>

                        </div>
                    </ResponsiveDiv>

                    <ResponsiveDiv
                        justifyContent="center"
                        // alignItems="center"
                        textAlign="center"
                        // padding="20px"
                        margin="10px"
                        width="172px"
                        height="73px"
                        //maxWidth="1200px"
                        directionOnSmall="column"
                    >
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <ImageContainer
                                width="83px"      // Set the width of the container
                                height="43px"     // Set the height of the container
                                imageSrc={bookedFemale} // Image source
                            />
                            <Text
                                fontWeight={600}
                                fontSize={16}
                                fontFamily="Inter"
                                color="#1E1E1E"
                            // style={{ textTransform: 'uppercase' }} // Pass additional styles if needed
                            >
                                Booked (Female)
                            </Text>
                        </div>
                    </ResponsiveDiv>



                    <ResponsiveDiv
                        justifyContent="center"
                        // alignItems="center"
                        textAlign="center"
                        // padding="20px"
                        margin="10px"
                        width="172px"
                        height="73px"
                        // maxWidth="1200px"
                        directionOnSmall="column"
                    >
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <ImageContainer
                                width="83px"      // Set the width of the container
                                height="43px"     // Set the height of the container
                                imageSrc={bookedMale} // Image source
                            />

                            <Text
                                fontWeight={600}
                                fontSize={16}
                                fontFamily="Inter"
                                color="#1E1E1E"
                            // style={{ textTransform: 'uppercase' }} // Pass additional styles if needed
                            >
                                Booked (Male)
                            </Text>
                        </div>
                    </ResponsiveDiv>
                </div>

            </ResponsiveDiv>





            <ResponsiveDiv
                justifyContent="center"
                // alignItems="center"
                textAlign="center"
                // padding="20px"
                // margin="10px"
                width="100%"
                // height="300px"
                maxWidth="100%"
                directionOnSmall="column"
            >
                <ResponsiveDiv
                    justifyContent="space-between"
                    // alignItems="center"
                    textAlign="center"
                    padding="10px"
                    // margin="10px"
                    // width="100%"
                    // height="300px"
                    maxWidth="990px"
                    directionOnSmall="column"
                >
                    <div style={{ textAlign: 'left',marginTop:10 }}>
                        <Text
                            fontWeight={600}
                            fontSize={24}
                            fontFamily="Inter"
                            color="#1E1E1E"
                        // style={{ textTransform: 'uppercase' }} // Pass additional styles if needed
                        >
                            Samudra
                        </Text>
                        <div style={{ marginTop: 10 }}>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div>
                                    <ImageContainer
                                        width="83px"      // Set the width of the container
                                        height="43px"     // Set the height of the container
                                        imageSrc={bookedMale} // Image source
                                    />
                                    <ImageContainer
                                        width="83px"      // Set the width of the container
                                        height="43px"     // Set the height of the container
                                        imageSrc={bookedMale} // Image source
                                    />
                                </div>
                                <div style={{ marginLeft: 20 }}>
                                    <ImageContainer
                                        width="83px"      // Set the width of the container
                                        height="43px"     // Set the height of the container
                                        imageSrc={bookedMale} // Image source
                                    />
                                    <ImageContainer
                                        width="83px"      // Set the width of the container
                                        height="43px"     // Set the height of the container
                                        imageSrc={bookedMale} // Image source
                                    />
                                </div>
                            </div>


                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 21 }}>
                                <div>
                                    <ImageContainer
                                        width="83px"      // Set the width of the container
                                        height="43px"     // Set the height of the container
                                        imageSrc={bookedMale} // Image source
                                    />
                                    <ImageContainer
                                        width="83px"      // Set the width of the container
                                        height="43px"     // Set the height of the container
                                        imageSrc={bookedMale} // Image source
                                    />
                                </div>
                                <div style={{ marginLeft: 20 }}>
                                    <ImageContainer
                                        width="83px"      // Set the width of the container
                                        height="43px"     // Set the height of the container
                                        imageSrc={bookedMale} // Image source
                                    />
                                    <ImageContainer
                                        width="83px"      // Set the width of the container
                                        height="43px"     // Set the height of the container
                                        imageSrc={bookedMale} // Image source
                                    />
                                </div>
                            </div>




                        </div>
                    </div>



                    <div style={{ textAlign: 'left',marginTop:10 }}>
                        <Text
                            fontWeight={600}
                            fontSize={24}
                            fontFamily="Inter"
                            color="#1E1E1E"
                        // style={{ textTransform: 'uppercase' }} // Pass additional styles if needed
                        >
                            Pawan
                        </Text>
                        <div style={{ marginTop: 10 }}>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div>
                                    <ImageContainer
                                        width="83px"      // Set the width of the container
                                        height="43px"     // Set the height of the container
                                        imageSrc={bookedMale} // Image source
                                    />
                                    <ImageContainer
                                        width="83px"      // Set the width of the container
                                        height="43px"     // Set the height of the container
                                        imageSrc={bookedMale} // Image source
                                    />
                                </div>
                                <div style={{ marginLeft: 20 }}>
                                    <ImageContainer
                                        width="83px"      // Set the width of the container
                                        height="43px"     // Set the height of the container
                                        imageSrc={bookedMale} // Image source
                                    />
                                    <ImageContainer
                                        width="83px"      // Set the width of the container
                                        height="43px"     // Set the height of the container
                                        imageSrc={bookedMale} // Image source
                                    />
                                </div>
                            </div>


                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 21 }}>
                                <div>
                                    <ImageContainer
                                        width="83px"      // Set the width of the container
                                        height="43px"     // Set the height of the container
                                        imageSrc={bookedFemale} // Image source
                                    />
                                    <ImageContainer
                                        width="83px"      // Set the width of the container
                                        height="43px"     // Set the height of the container
                                        imageSrc={bookedFemale} // Image source
                                    />
                                </div>

                            </div>




                        </div>
                    </div>

                    <div style={{ textAlign: 'left',marginTop:10 }}>
                        <Text
                            fontWeight={600}
                            fontSize={24}
                            fontFamily="Inter"
                            color="#1E1E1E"
                        // style={{ textTransform: 'uppercase' }} // Pass additional styles if needed
                        >
                            Bhoomi
                        </Text>
                        <div style={{ marginTop: 10 }}>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div>
                                    <ImageContainer
                                        width="83px"      // Set the width of the container
                                        height="43px"     // Set the height of the container
                                        imageSrc={bookedMale} // Image source
                                    />
                                    <ImageContainer
                                        width="83px"      // Set the width of the container
                                        height="43px"     // Set the height of the container
                                        imageSrc={bookedMale} // Image source
                                    />
                                </div>
                                <div style={{ marginLeft: 20 }}>
                                    <ImageContainer
                                        width="83px"      // Set the width of the container
                                        height="43px"     // Set the height of the container
                                        imageSrc={bookedMale} // Image source
                                    />
                                    <ImageContainer
                                        width="83px"      // Set the width of the container
                                        height="43px"     // Set the height of the container
                                        imageSrc={bookedMale} // Image source
                                    />
                                </div>
                            </div>


                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 21 }}>
                                <div>
                                    <ImageContainer
                                        width="83px"      // Set the width of the container
                                        height="43px"     // Set the height of the container
                                        imageSrc={bookedFemale} // Image source
                                    />

                                </div>

                            </div>




                        </div>
                    </div>

                </ResponsiveDiv>
            </ResponsiveDiv>


            <Text

                fontWeight={600}
                fontSize={24}
                fontFamily="Inter"
                color="#1E1E1E"
            // style={{ textTransform: 'uppercase' }} // Pass additional styles if needed
            >
                * This is view only. 
                {/* <button className="button" onClick={() => scrollToSection()} style={{ marginTop: 20, border: '0.5px solid grey', zIndex: 1000, boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', marginLeft: 10 }}>Book now</button> */}
                <span id={'pricing'}></span>
            </Text>

            {/* <a onClick={()=>scrollToSection()} style={{color:'blue', marginLeft:10, fontSize:20,cursor:'pointer'}}>click here</a> */}

        </>)
}

export default RoomsSection
