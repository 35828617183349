import { Col, Row } from "reactstrap";
import Text from "../components/TextSection/Text";
import Carousel from "../components/Carousels/Carousel";
import image01 from '../assets/images/malnard1.png'
import image02 from '../assets/images/malnard2.png'
import image03 from '../assets/images/malnard3.png'
import { useState } from "react";
import ImageModal from "../components/Modals/ImageModal";
import styled from "styled-components";

const CaroselSection = () => {
    const [modal, setModal] = useState(false)

    const images = [
        image01,
        image02,
        image03
    ];



    <button style={{ width: '23%', backgroundColor: '#562BD4', color: 'white', padding: 12, borderRadius: 8, borderColor: '#562BD4' }}>
        Visit website
    </button>

    const StyledButton = styled.button`
  background-color: ${({ backgroundColor }) => backgroundColor || '#562BD4'};
  color: ${({ color }) => color || 'white'};
  border-radius: ${({ borderRadius }) => borderRadius || '9px'};
  border-color: ${({ borderColor }) => borderColor || '#562BD4'};
  padding: ${({ padding }) => padding || '10'};
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || 'auto'};
  max-width: ${({ maxWidth }) => maxWidth || 'none'};
  

  // Media query for responsiveness
   @media (max-width: 432px) {
    // Adjust styles for smaller screens if necessary
  padding: ${({ smallPadding }) => smallPadding || '10'};
  width: ${({ smallWidth }) => smallWidth || '100%'};
  height: ${({ smallHeight }) => smallHeight || 'auto'};
  max-width: ${({ smallMaxWidth }) => smallMaxWidth || 'none'};
  }
  @media (max-width: 768px) {
    // Adjust styles for smaller screens if necessary
    flex-direction: ${({ directionOnSmall }) => directionOnSmall || 'row'};
  }
`;
    return (
        <>

            <div style={{ marginTop: 40 }}>
                <Text
                    fontWeight={700}
                    fontSize={48}
                    fontFamily="Inter"
                    color="#1E1E1E"
                >
                    Malnad Ultra 2024
                </Text>
            </div>

            <div>
                <Row noGutters>
                    <Col md={6} xs={12} style={{ padding: 24 }} onClick={() => setModal(true)}>
                        <Carousel
                            imageUrls={[
                                image01,
                                image02,
                                image03
                            ]}
                        />
                    </Col>
                    <Col xs={12} md={6} style={{ textAlign: 'left', marginTop: '5%', paddingRight: '20px', padding: 10, }}>
                        <div style={{ textAlign: 'justify', paddingRight: '1%' }}>
                            <Text
                                fontWeight={400}
                                fontSize={18}
                                fontFamily="Inter"
                                color="#1E1E1E"
                            >
                                The 8th edition of Tekion Malnad Ultra offers an elevated trail running experience in South India’s Malnad region, nestled within the Indian coffee heartland. The race is set for 23rd November this year and runners can experience elevation ranging from 1000m to 1600m.
                            </Text>
                        </div>

                        <div style={{ marginTop: 24, textAlign: 'justify', paddingRight: '1%' }}>
                            <Text
                                fontWeight={400}
                                fontSize={18}
                                fontFamily="Inter"
                                color="#1E1E1E"
                            >
                                This event  is ITRA-certified and serves as a qualifier for the Western states endurance run.
                            </Text>
                        </div>

                       {/* <div style={{ marginTop: 24, }}>
                            <a href="https://malnadultra.com/" target="_blank" rel="noopener noreferrer">
                                <StyledButton
                                    width='25%'
                                    backgroundColor='#562BD4'
                                    color='white'
                                    padding='12px'
                                    borderRadius='8px'
                                    borderColor='#562BD4'
                                    smallMaxWidth='100%'
                                    smallPadding='8px'
                                >
                                    Visit website
                                </StyledButton>
                            </a>
                        </div>*/}
                    </Col>
                </Row>
            </div>

            <ImageModal modal={modal} setModal={setModal} images={images} />
        </>
    )
}

export default CaroselSection;