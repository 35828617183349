import React from 'react';
import styled from 'styled-components';

// Styled component for the card container
const CardContainer = styled.div`
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  max-width: ${({ width }) => width || '100%'};
  max-height: ${({ height }) => height || 'auto'};
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  
  @media (max-width: 768px) {
    margin: 15px;
  }

  @media (max-width: 480px) {
    margin: 10px;
  }
`;

// Styled component for the image
const CardImage = styled.img`
  width: 100%;
  height: ${({ imageHeight }) => imageHeight || 'auto'};
  object-fit: cover;
  border-bottom: 1px solid #ddd;

  @media (max-width: 768px) {
    height: ${({ imageHeight }) => imageHeight || '200px'};
  }
  
  @media (max-width: 480px) {
    height: ${({ imageHeight }) => imageHeight || '150px'};
  }
`;

// Styled component for the card content
const CardContent = styled.div`
  padding: 15px;
`;

// Styled component for the heading
const Heading = styled.h2`
  font-size: ${({ headingSize }) => headingSize || '24px'};
  color: ${({ headingColor }) => headingColor || '#333'};
  font-weight: ${({ headingWeight }) => headingWeight || 'normal'};
  font-family: ${({ headingFontFamily }) => headingFontFamily || 'Arial, sans-serif'};
  margin: 10px 0;
  
  @media (max-width: 768px) {
    font-size: ${({ headingSize }) => headingSize || '20px'};
  }

  @media (max-width: 480px) {
    font-size: ${({ headingSize }) => headingSize || '18px'};
  }
`;

// Styled component for the sub-heading
const SubHeading = styled.h3`
  font-size: 18px;
  color: #666;
  margin: 0;
  
  @media (max-width: 768px) {
    font-size: 16px;
  }

  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

const StyledCardImage = ({ 
  imageSrc, 
  headingText, 
  subHeadingText,
  headingSize,
  headingColor,
  headingWeight,
  headingFontFamily,
  cardWidth,
  cardHeight,
  imageHeight
}) => {
  return (
    <CardContainer width={cardWidth} height={cardHeight}>
      <CardImage src={imageSrc} alt={headingText} imageHeight={imageHeight} />
      <CardContent>
        <Heading 
          headingSize={headingSize}
          headingColor={headingColor}
          headingWeight={headingWeight}
          headingFontFamily={headingFontFamily}
        >
          {headingText}
        </Heading>
        <SubHeading>{subHeadingText}</SubHeading>
      </CardContent>
    </CardContainer>
  );
};

export default StyledCardImage;
