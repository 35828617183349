import { Col, Row } from "reactstrap";
import Text from "../components/TextSection/Text";
import StyledCard from "../components/Cards/StyledCard";
import itra1 from '../assets/images/itra1.png'
import itra2 from '../assets/images/itra2.png'
import itra4 from '../assets/images/itra4.png'

const RaceSection = () => {
    return (
        <>
            <div style={{ marginTop: 20 }}>
                <Text
                    fontWeight={700}
                    fontSize={48}
                    fontFamily="Inter"
                    color="#1E1E1E"
                >
                    Races
                </Text>
            </div>

            <div style={{ marginLeft: '2%', marginRight: '2%', }}>
                <Row>
                    <Col md={4} >
                        <StyledCard
                            imageSrc={itra4}
                            imageWidth="234px"
                            imageHeight="100px"
                            imageAlignment="center"
                            chip1Text="100 KM"
                            chip2Text="21 hours"
                            mainText="Net Ascent/Descent: +3429/-3429 m,
               Start Time: 6:30 a.m"
                            mainText2="Runners must complete two loops of 50K in 21 hours across five coffee plantations. ."
                            bgColor="#F7EDF5"
                            height={358}
                        />

                    </Col>
                    <Col md={4}>
                        <StyledCard
                            imageSrc={itra2}
                            imageWidth="234px"
                            imageHeight="100px"
                            imageAlignment="center"
                            chip1Text="50 KM"
                            chip2Text="10 hours"
                            mainText="Net Ascent/Descent: +1712/-1712 m,
               Start Time: 7:00 a.m"
                            mainText2="Runners must complete a single loop of 50K across five coffee plantations in 10 hours"
                            bgColor="#FFFDE5"
                            height={358}
                        />

                    </Col>
                    <Col md={4}>
                        <StyledCard
                            imageSrc={itra1}
                            imageWidth="234px"
                            imageHeight="100px"
                            imageAlignment="left"
                            chip1Text="30 KM"
                            chip2Text="6.5 hours"
                            mainText="Net Ascent/Descent: +1210/-1210 m,
Start Time: 7:30 a.m"
                            mainText2="Entry category is designed to introduce city runners to trail running. Runners must complete 30K in 6.5 hours."
                            bgColor="#F5FFD4"
                            height={358}
                        />

                    </Col>
                </Row>

            </div>
        </>
    )
}

export default RaceSection;