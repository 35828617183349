import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Table } from 'reactstrap';
import styled from 'styled-components';

function FemaleSizeModal(props) {
 

    const toggle = () => props.setModal(false);

    const tableData = [
        {
            size: 'XS',
            chestSize: '31.5',
            ShoulderSize: '14.5'
        },
        {
        size: 'S',
        chestSize: '34',
        ShoulderSize: '15'
    },
    {
        size: 'M',
        chestSize: '46',
        ShoulderSize: '15.5'
    },
    {
        size: 'L',
        chestSize: '48',
        ShoulderSize: '15.8'
    },
    {
        size: 'XL',
        chestSize: '41',
        ShoulderSize: '16'
    },
    {
        size: '2XL',
        chestSize: '43',
        ShoulderSize: '16.4'
    }
    ]


    const CenteredTd = styled.td`
  text-align: center;
`;
    const CenteredTh = styled.th`
text-align: center;
`;

    return (
        <div>
           
            <Modal isOpen={props.modal} toggle={toggle} {...props}>
                <ModalHeader toggle={toggle}>Women's T-Shirt Size Chart</ModalHeader>
                <ModalBody>
                    <Table borderless responsive>
                        <thead>
                            <tr>

                                <CenteredTh>
                                     Size
                                </CenteredTh>
                                <CenteredTh>
                                    Bust Size (IN)
                                </CenteredTh>
                                <CenteredTh>
                                    Across Shoulder (IN)
                                </CenteredTh>
                            </tr>
                        </thead>
                        <tbody>
                            {tableData.map((dta) => {
                                return <tr>

                                    <CenteredTd>
                                        {dta.size}
                                    </CenteredTd>
                                    <CenteredTd>
                                        {dta.chestSize}
                                    </CenteredTd>
                                    <CenteredTd>
                                        {dta.ShoulderSize}
                                    </CenteredTd>
                                </tr>
                            })}


                        </tbody>
                    </Table>
                </ModalBody>

            </Modal>
        </div>
    );
}

export default FemaleSizeModal;