import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Styled component for the responsive div
const StyledDiv = styled.div`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  align-items: ${({ alignItems }) => alignItems || 'flex-start'};
  text-align: ${({ textAlign }) => textAlign || 'left'};
  padding: ${({ padding }) => padding || '0'};
  margin: ${({ margin }) => margin || '0'};
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || 'auto'};
  box-sizing: border-box; // Ensures padding and border are included in the width and height
  max-width: ${({ maxWidth }) => maxWidth || 'none'};
  

  // Media query for responsiveness
   @media (max-width: 432px) {
    // Adjust styles for smaller screens if necessary
    align-items: ${({ alignItems }) => alignItems || 'center'};
  }
  @media (max-width: 768px) {
    // Adjust styles for smaller screens if necessary
    flex-direction: ${({ directionOnSmall }) => directionOnSmall || 'row'};
  }
`;

const ResponsiveDiv = ({
  justifyContent,
  alignItems,
  textAlign,
  padding,
  margin,
  width,
  height,
  maxWidth,
  directionOnSmall,
  children
}) => {
  return (
    <StyledDiv
      justifyContent={justifyContent}
      alignItems={alignItems}
      textAlign={textAlign}
      padding={padding}
      margin={margin}
      width={width}
      height={height}
      maxWidth={maxWidth}
      directionOnSmall={directionOnSmall}
    >
      {children}
    </StyledDiv>
  );
};

// Define PropTypes for the component
ResponsiveDiv.propTypes = {
  justifyContent: PropTypes.string,
  alignItems: PropTypes.string,
  textAlign: PropTypes.string,
  padding: PropTypes.string,
  margin: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  maxWidth: PropTypes.string,
  directionOnSmall: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default ResponsiveDiv;
