import React from 'react';
import './BackGround.css'; // Custom styles

function BackgroundSection({ imageUrl, width = '100vw', height = '100vh', borderRadius = '0', children }) {
  const sectionStyle = {
    background: `url(${imageUrl}) no-repeat center center`,
    backgroundSize: 'cover',
    width: width,
    height: height,
    borderRadius: borderRadius,
    overflow: 'hidden',  // Ensure the border radius applies to content and image
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const contentStyle = {
    zIndex: 1,  // Ensure content is on top of the background
    textAlign: 'center',  // Center text inside the content
  };

  const overlayStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black overlay
    zIndex: 0,  // Ensure the overlay is behind the content
  };

  return (
    <div className="background-section" style={sectionStyle}>
      <div style={overlayStyle} />  {/* Overlay for darkening the image */}
      <div className="content" style={contentStyle}>
        {children}
      </div>
    </div>
  );
}

export default BackgroundSection;
