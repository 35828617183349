import React from 'react';
import styled from 'styled-components';
import Text from '../TextSection/Text';

// Styled component for the container
const Container = styled.div`
  background-color: ${({ bgColor }) => bgColor || '#fff'};
  padding: 24px;
  border-radius: 16px;
  text-align: center;
  height: 358px;
    @media (max-width: 432px) {
    margin-top: 10px; // Adjust marginTop for mobile screens
     height: 400px;
     padding: 12px;
  }
`;

// Styled component for the image
const Image = styled.img`
  width: ${({ width }) => width || '50px'};
  height: ${({ height }) => height || '50px'};
  object-fit: cover;
  margin-bottom: 10px;
  display: block;
//   margin-left: auto;
//   margin-right: auto;
`;

// Styled component for the chips container
const ChipsContainer = styled.div`
  display: flex;
//   justify-content: center;
  gap: 10px;
  margin-bottom: 10px;
`;

// Styled component for each chip
const Chip = styled.div`
  background-color: #e0e0e0;
  padding: 5px 10px;
  border-radius: 16px;
  font-size: 14px;
`;


const StyledCard = ({ 
  imageSrc, 
  imageWidth, 
  imageHeight, 
  imageAlignment, 
  chip1Text, 
  chip2Text, 
  mainText,
  mainText2, 
  bgColor 
}) => {
  return (
    <Container bgColor={bgColor}>
      <Image 
        src={imageSrc} 
        width={imageWidth} 
        height={imageHeight} 
        style={{ alignSelf: imageAlignment }} 
      />
      <ChipsContainer>
        <Chip>{chip1Text}</Chip>
        <Chip>{chip2Text}</Chip>
      </ChipsContainer>
      <div style={{marginTop:24,textAlign:'left'}}>
      <Text
          fontWeight={400}
          fontSize={16}
          fontFamily="Inter"
          color="#1E1E1E"
        >
          {mainText}
        </Text>
      </div>
      <div style={{marginTop:24,textAlign:'left'}}>
      <Text
          fontWeight={400}
          fontSize={16}
          fontFamily="Inter"
          color="#1E1E1E"
        >
          {mainText2}
        </Text>
      </div>
      
    </Container>
  );
};

export default StyledCard;
