import React from "react";
import { Col, Row } from "reactstrap";
import Text from "../../components/TextSection/Text";

function RefundAndCancellation() {
  return (
    <div style={{ padding: "10px", lineHeight: "1.6" }}>
      <Row nogutters>
        <Col
          xs={12}
          md={12}
          style={{
            textAlign: "left",
            marginTop: "0%",
            paddingRight: "20px",
           
          }}
        >
          <div style={{ marginTop: 4 }}>
            <Text
              fontWeight={600}
              fontSize={20}
              fontFamily="Inter"
              color="#1E1E1E"
            >
              Refunds And Cancellations Policy
            </Text>
          </div>
          <div style={{ marginTop: 4 }}>
            <Text
              fontWeight={600}
              fontSize={16}
              fontFamily="Inter"
              color="#1E1E1E"
            >
              1. Booking and Payment Terms
            </Text>
          </div>
          <div
            style={{
              textAlign: "justify",
              paddingRight: "1%",
              lineHeight: "14px",
            }}
          >
            <Text
              fontWeight={400}
              fontSize={12}
              fontFamily="Inter"
              color="#1E1E1E"
            >
              Users can make travel bookings through the App. All bookings are
              subject to availability and the policies of third-party service
              providers.
            </Text>
          </div>

          <div
            style={{
              textAlign: "justify",
              paddingRight: "1%",
              lineHeight: "14px",
            }}
          >
            <Text
              fontWeight={400}
              fontSize={12}
              fontFamily="Inter"
              color="#1E1E1E"
            >
              Payments for bookings must be made using the payment methods
              supported by the App. Payment terms (e.g., advance payments,
              payment deadlines) are governed by the third-party provider’s
              policies.
            </Text>
          </div>

          <div
            style={{
              textAlign: "justify",
              paddingRight: "1%",
              lineHeight: "14px",
            }}
          >
            <Text
              fontWeight={400}
              fontSize={12}
              fontFamily="Inter"
              color="#1E1E1E"
            >
              The prices listed on the App are subject to change and may include
              applicable taxes and fees.
            </Text>
          </div>

          <div style={{ marginTop: 8 }}>
            <Text
              fontWeight={600}
              fontSize={16}
              fontFamily="Inter"
              color="#1E1E1E"
            >
              2. Cancellations and Refunds
            </Text>
          </div>

          <div
            style={{
              textAlign: "justify",
              paddingRight: "1%",
              lineHeight: "14px",
            }}
          >
            <Text
              fontWeight={400}
              fontSize={12}
              fontFamily="Inter"
              color="#1E1E1E"
            >
              Cancellations and refund requests are subject to the cancellation
              policies of third-party service providers (e.g., hotels, tour
              operators). Cancellation and refund policies applicable to
              specific services and packages are mentioned on our App. Please
              refer to these policies before booking.{" "}
            </Text>
          </div>

          <div
            style={{
              textAlign: "justify",
              paddingRight: "1%",
              lineHeight: "14px",
            }}
          >
            <Text
              fontWeight={400}
              fontSize={12}
              fontFamily="Inter"
              color="#1E1E1E"
            >
              Wherever applicable, refunds will be processed by Adventra within
              5-7 working days.
            </Text>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default RefundAndCancellation;
