import React, { useState, useEffect } from 'react';

function Text({ 
  fontWeight = 700, 
  fontSize = 'clamp(1rem, 2vw, 3rem)', // Default responsive font size
  fontFamily = 'Inter', 
  color = 'black', 
  alignment = 'left', 
  padding = '0px', 
  margin = '0px', 
  children, 
  style = {} 
}) {
  const [responsiveFontSize, setResponsiveFontSize] = useState(fontSize);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setResponsiveFontSize('15px'); // Font size for tablets and phones
      } else {
        setResponsiveFontSize(fontSize); // Default font size
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initial call

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [fontSize]);

  const textStyle = {
    fontWeight: fontWeight,
    fontSize: responsiveFontSize, // Apply responsive font size
    fontFamily: fontFamily,
    color: color,
    textAlign: alignment, // Alignment (left, center, right)
    padding: padding,     // Padding
    margin: margin,       // Margin
    lineHeight: 1,
    ...style,             // Allow additional styles to be passed via props
 
  };

  return (
    <span style={textStyle}>
      {children}
    </span>
  );
}

export default Text;
