import { Link } from "react-router-dom";
import Text from "../components/TextSection/Text";
import { Col, Nav, NavLink, Row } from "reactstrap";

const FooterSection = () => {
  return (
    <>
      <div style={{ backgroundColor: '#f0f0f0', marginTop: '7%', paddingBottom: '6%' }}>
        <div style={{ paddingTop: 77, textAlign: 'left', paddingLeft: '5%' }}>
          <Text
            fontWeight={700}
            fontSize={48}
            fontFamily="Inter"
            color="#000000"
          // style={{ textTransform: 'uppercase' }} // Pass additional styles if needed
          >About Adventra
          </Text>
        </div>
        <Row>     
          <Col xs={12} sm={8}>       

        <div style={{ paddingLeft: '7%', paddingTop: 22, paddingRight: '7%', textAlign: 'justify' }}>
          <Text
            fontWeight={400}
            fontSize={20}
            fontFamily="Inter"
            color="#000000"
          //   style={{  ,}}
          // style={{ textTransform: 'uppercase' }} // Pass additional styles if needed
          >
            Adventra is your ultimate go-to platform, transforming how you plan, experience, and share your adventure travel. Whether you're into biking, hiking, running, or other adrenaline-pumping adventures, Adventra offers a seamless experience for both providers and adventurers. It provides a curated selection of activities, expert guides, customizable itineraries, and all the tools you need to explore the great outdoors with confidence and ease.
          </Text>
        </div>


        <div style={{ paddingLeft: '7%', paddingTop: 22, paddingRight: '7%', textAlign: 'left' }}>
          <Text
            fontWeight={400}
            fontSize={20}
            fontFamily="Inter"
            color="#000000"
          // style={{ textTransform: 'uppercase' }} // Pass additional styles if needed
          >
            Please contact +91-72043 50092 for your adventure travel needs
          </Text>
        </div>

        <div style={{ paddingLeft: '7%', paddingTop: 22, paddingRight: '7%', textAlign: 'left' }}>
          <Text
            fontWeight={400}
            fontSize={20}
            fontFamily="Inter"
            color="#000000"
          // style={{ textTransform: 'uppercase' }} // Pass additional styles if needed
          >
            © 2024 Adventra Technologies Private Limited
          </Text>
          <div style={{ paddingLeft: 25 }}>
            <Text
              fontWeight={400}
              fontSize={20}
              fontFamily="Inter"
              color="#000000"
            >
              {'  CIN: U63999KA2024PTC188842'}
            </Text>
          </div>
        </div>

        </Col>
       
        <Col style={{ textAlign: 'left' }}>
        <Nav vertical textAlign='left' >
              <NavLink href="/TermsAndConditions">Terms And Conditions</NavLink>
              <NavLink href="/PrivacyPolicy">Privacy Policy</NavLink>
              <NavLink href="/Disclaimer">Disclaimer</NavLink>
              <NavLink href="/RefundsAndCancellationPolicy">Refunds And Cancellation Policy</NavLink>

            </Nav>
        </Col>
        </Row>
      </div>
    </>
  )
}

export default FooterSection;