import React from 'react'

function TravelProvider() {
  return (
    <div style={{ padding: '20px', lineHeight: '1.6' }}>
      <h1>Travel Provider Terms and Conditions</h1>
      {/* <p>By using Adventra Technologies Private Limited, you acknowledge and agree to the following:</p> */}
      
      <h2>1. Definitions</h2>
      <p>Company: Refers to Adventra Technologies Private Limited, which facilitates travel bookings and services via its digital platform.</p>
      <p>Provider: Refers to the entity providing travel-related services such as accommodation, tours, activities, transportation, etc.</p>
      <p>Customers: Individuals or entities who book travel services through the Company’s app.</p>
      
      <h2>2. Scope of Services</h2>
      <p>The Provider agrees to offer the following services through the Company’s app: [List specific services, e.g., accommodation, tours, transportation].</p>
      <p>All services must meet the standards set by the Company, including quality, safety, and legal requirements applicable in the destination.</p>
      
      <h2>3. Booking and Availability</h2>
      <p>The Provider agrees to make available its services through the Company’s platform and update the availability of services in real-time to avoid double bookings.</p>
      <p>The Company will handle customer bookings and communicate them to the Provider via email, the platform dashboard, or API integration.</p>
      
      <h2>4. Rates and Commissions</h2>
      <p>Rates: The Provider agrees to offer rates for services as detailed in Schedule A of this Agreement. Any rate changes must be communicated to the Company in writing at least [insert number] days in advance.</p>
      <p>Commissions: The Company is entitled to a commission of [insert percentage]% for each confirmed booking made through the app. The commission will be deducted from the total amount paid by the customer.</p>
      <p>Payment Terms: The Company will transfer payments to the Provider, minus the commission, within [insert number] days after the service has been provided, subject to customer satisfaction or any agreed terms regarding cancellations.</p>
      
      <h2>5. Cancellations and Refunds</h2>
      <p>The Provider must clearly state their cancellation and refund policies, which will be communicated to customers during the booking process.</p>
      <p>In cases where a customer cancels a booking or requests a refund, the Company will adhere to the Provider’s policies and facilitate the refund process if applicable.</p>
      <p>In the event the Provider cancels a confirmed booking, the Provider will be responsible for refunding the customer in full and providing an alternative of equal or higher value, subject to the customer’s approval.</p>
      
      <h2>6. Service Standards and Obligations</h2>
      <p>The Provider agrees to deliver services as advertised and described on the Company’s platform.</p>
      <p>The Provider shall ensure that all services meet the highest industry standards and comply with local laws, health and safety regulations, and any other applicable standards.</p>
      <p>In the case of significant deviations from the service description or customer complaints, the Company reserves the right to suspend or terminate the Provider's services from the platform.</p>

      <h2>7. Liability and Insurance</h2>
      <p>The Provider is solely responsible for the quality, safety, and legality of the services provided. The Company acts as an intermediary and is not liable for any claims arising from the Provider’s failure to meet service standards.</p>
      <p>The Provider agrees to maintain adequate insurance coverage (e.g., general liability insurance) to cover any claims or incidents arising from the services provided. Proof of insurance must be provided upon request.</p>

      <h2>8. Marketing and Intellectual Property</h2>
      <p>The Provider grants the Company the right to use the Provider’s brand, logo, and service descriptions for marketing and promotional purposes on the Company’s platform and affiliated channels.</p>
      <p>The Company may create marketing content (e.g., blog posts, social media content) based on the Provider’s services to drive bookings. The Provider agrees to allow the Company to use content (photos, descriptions) related to its services on the platform.</p>

      <h2>9. Confidentiality</h2>
      <p>Both Parties agree to maintain the confidentiality of all proprietary information exchanged between them during the term of this Agreement, including pricing, customer data, and business practices. Confidential information shall not be disclosed to third parties without prior written consent, except as required by law.</p>

      <h2>10. Term and Termination</h2>
      <p>Term: This Agreement shall commence on the Effective Date and continue for [insert number] years, unless terminated earlier.</p>
      <p>Termination by Either Party: Either party may terminate this Agreement by providing [insert number] days’ written notice.</p>
      <p>Termination for Cause: The Company may terminate this Agreement immediately if the Provider breaches any terms of this Agreement, fails to deliver services as described, or engages in fraudulent or illegal activity.</p>
      <p>Upon termination, the Provider must fulfill all confirmed bookings and settle any outstanding payments owed to the Company.</p>

      <h2>11. Dispute Resolution</h2>
      <p>In the event of a dispute between the Parties, both Parties agree to attempt to resolve the matter through good-faith negotiations. If the dispute cannot be resolved amicably, it will be submitted to binding arbitration under the rules of [Insert Jurisdiction] Arbitration Association.</p>

      <h2>12. Indemnification</h2>
      <p>The Provider agrees to indemnify and hold harmless the Company, its directors, employees, and affiliates, from any claims, damages, liabilities, or expenses arising out of or related to the Provider’s services, including customer disputes, legal violations, or third-party claims.</p>

      <h2>13. Governing Law</h2>
      <p>This Agreement shall be governed by and construed in accordance with the laws of [Insert Jurisdiction]. Any legal proceedings related to this Agreement shall be conducted in the courts of [Insert Jurisdiction].</p>

      <h2>14. Amendments</h2>
      <p>Any amendments or modifications to this Agreement must be made in writing and signed by both Parties.</p>

      <h2>15. Entire Agreement</h2>
      <p>This Agreement constitutes the entire agreement between the Parties with respect to its subject matter and supersedes all prior negotiations, understandings, and agreements.</p>

      <h2>16. Notices</h2>
      <p>Any notice required under this Agreement must be in writing and sent to the relevant addresses:</p>

      {/* Add more sections as needed */}
    </div>
  )
}

export default TravelProvider
