import React from 'react'
import Text from '../../components/TextSection/Text'
import { Col, Row } from 'reactstrap'

function Disclaimer() {
  return (
    <div><div style={{ padding: "10px", lineHeight: "1.6" }}>
    <Row nogutters>
      <Col
        xs={12}
        md={12}
        style={{
          textAlign: "left",
          marginTop: "0%",
          paddingRight: "20px",
         
        }}
      >
        <div style={{ marginTop: 4 }}>
          <Text
            fontWeight={600}
            fontSize={20}
            fontFamily="Inter"
            color="#1E1E1E"
          >
Disclaimer
          </Text>
        </div>
        <div
          style={{
            textAlign: "justify",
            paddingRight: "1%",
            lineHeight: "14px",
          }}
        >
          <Text
            fontWeight={400}
            fontSize={12}
            fontFamily="Inter"
            color="#1E1E1E"
          >
            By using Adventra Technologies Private Limited, you acknowledge and agree to the following:
          </Text>
        </div>
        <div style={{ marginTop: 4 }}>
          <Text
            fontWeight={600}
            fontSize={16}
            fontFamily="Inter"
            color="#1E1E1E"
          >
           General Information
          </Text>
        </div>
        <div
          style={{
            textAlign: "justify",
            paddingRight: "1%",
            lineHeight: "14px",
          }}
        >
          <Text
            fontWeight={400}
            fontSize={12}
            fontFamily="Inter"
            color="#1E1E1E"
          >
            The information provided on the app, including travel packages, recommendations, and third-party services, is for general informational purposes only. While we strive to ensure accuracy, we do not guarantee the completeness, reliability, or availability of the information.
          </Text>
        </div>

        <div style={{ marginTop: 4 }}>
          <Text
            fontWeight={600}
            fontSize={16}
            fontFamily="Inter"
            color="#1E1E1E"
          >
            Third-Party Services
          </Text>
        </div>

        <div
          style={{
            textAlign: "justify",
            paddingRight: "1%",
            lineHeight: "14px",
          }}
        >
          <Text
            fontWeight={400}
            fontSize={12}
            fontFamily="Inter"
            color="#1E1E1E"
          >
            Adventra Technologies Private Limited acts as an intermediary between users and third-party providers such as airlines, hotels, tour operators, and transportation services. We do not directly manage or control these services, and therefore, we are not responsible for the quality, availability, or performance of third-party services.
          </Text>
        </div>

        <div
          style={{
            textAlign: "justify",
            paddingRight: "1%",
            lineHeight: "14px",
          }}
        >
          <Text
            fontWeight={400}
            fontSize={12}
            fontFamily="Inter"
            color="#1E1E1E"
          >
           Users must refer to the terms and conditions of each third-party provider, as their policies (e.g., cancellations, refunds, or delays) govern the service relationship.
          </Text>
        </div>

        <div style={{ marginTop: 8 }}>
          <Text
            fontWeight={600}
            fontSize={16}
            fontFamily="Inter"
            color="#1E1E1E"
          >
            Liability
          </Text>
        </div>

     

        <div
          style={{
            textAlign: "justify",
            paddingRight: "1%",
            lineHeight: "14px",
          }}
        >
          <Text
            fontWeight={400}
            fontSize={12}
            fontFamily="Inter"
            color="#1E1E1E"
          >
           Adventra Technologies Private Limited is not liable for any loss, damage, injury, or inconvenience caused directly or indirectly by your use of the app or third-party services booked through the app.
          </Text>
        </div>

        <div
          style={{
            textAlign: "justify",
            paddingRight: "1%",
            lineHeight: "14px",
          }}
        >
          <Text
            fontWeight={400}
            fontSize={12}
            fontFamily="Inter"
            color="#1E1E1E"
          >
Travel involves inherent risks, and users are encouraged to review all relevant travel advisories, safety protocols, and insurance coverage before making bookings.          </Text>
        </div>


        <div style={{ marginTop: 8 }}>
          <Text
            fontWeight={600}
            fontSize={16}
            fontFamily="Inter"
            color="#1E1E1E"
          >
            Changes to Services
          </Text>
        </div>


        <div
          style={{
            textAlign: "justify",
            paddingRight: "1%",
            lineHeight: "14px",
          }}
        >
          <Text
            fontWeight={400}
            fontSize={12}
            fontFamily="Inter"
            color="#1E1E1E"
          >
The services and information on the app may change without notice. We reserve the right to update or discontinue any aspect of the app at any time, without liability.          </Text>
        </div>

        <div style={{ marginTop: 8 }}>
          <Text
            fontWeight={600}
            fontSize={16}
            fontFamily="Inter"
            color="#1E1E1E"
          >
          Technical Issues
          </Text>
        </div>


        <div
          style={{
            textAlign: "justify",
            paddingRight: "1%",
            lineHeight: "14px",
          }}
        >
          <Text
            fontWeight={400}
            fontSize={12}
            fontFamily="Inter"
            color="#1E1E1E"
          >
While we aim to provide a smooth and uninterrupted user experience, we do not guarantee that the app will be free from errors, bugs, or downtime. Users are responsible for ensuring they have suitable devices and internet access.          </Text>
        </div>

      </Col>
    </Row>
  </div>
   </div>
  )
}

export default Disclaimer
