import React from "react";
import Text from "../../components/TextSection/Text";
import { Col, Row } from "reactstrap";

function TermAndCondition() {
  return (
    <div>
     <div style={{ padding: "10px", lineHeight: "1.6" }}>
      <Row nogutters>
        <Col
          xs={12}
          md={12}
          style={{
            textAlign: "left",
            marginTop: "0%",
            paddingRight: "20px",
           
          }}
        >
          <div style={{ marginTop: 4 }}>
            <Text
              fontWeight={600}
              fontSize={20}
              fontFamily="Inter"
              color="#1E1E1E"
            >
                Terms & Conditions - User Agreement{" "}
              </Text>
            </div>  <div
              style={{
                textAlign: "justify",
                paddingRight: "1%",
                lineHeight: "14px",
              }}
            >
              <Text
                fontWeight={400}
                fontSize={12}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                Effective Date: 1st September 2024
              </Text>
            </div>
            <div
              style={{
                textAlign: "justify",
                paddingRight: "1%",
                lineHeight: "14px",
              }}
            >
              <Text
                fontWeight={400}
                fontSize={12}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                Welcome to Adventra Technologies Private Limited. By accessing
                or using our application, website, or services (collectively,
                the “App”), you agree to comply with and be bound by the
                following User Agreement. If you do not agree to these terms,
                you should not use the App.
              </Text>
            </div>
            <div style={{ marginTop: 4 }}>
              <Text
                fontWeight={600}
                fontSize={16}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                1. Acceptance of Terms
              </Text>
            </div>

            <div
              style={{
                textAlign: "justify",
                paddingRight: "1%",
                lineHeight: "14px",
              }}
            >
              <Text
                fontWeight={400}
                fontSize={12}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                This User Agreement (“Agreement”) is a legally binding contract
                between you (“User”) and Adventra Technologies Private Limited
                (“Company”, “we”, “us”, “our”) governing your use of the App.
              </Text>
            </div>

            <div style={{ marginTop: 4 }}>
              <Text
                fontWeight={600}
                fontSize={16}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                2. Eligibility
              </Text>
            </div>

            <div
              style={{
                textAlign: "justify",
                paddingRight: "1%",
                lineHeight: "14px",
              }}
            >
              <Text
                fontWeight={400}
                fontSize={12}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                By using the App, you represent that you are at least 18 years
                of age and have the legal authority to enter into this
                Agreement. If you are using the App on behalf of an
                organization, you confirm that you are authorized to bind the
                organization to this Agreement.
              </Text>
            </div>

            <div style={{ marginTop: 4 }}>
              <Text
                fontWeight={600}
                fontSize={16}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                3. Services Offered
              </Text>
            </div>

            <div
              style={{
                textAlign: "justify",
                paddingRight: "1%",
                lineHeight: "14px",
              }}
            >
              <Text
                fontWeight={400}
                fontSize={12}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                Adventra provides travel-related services including but not
                limited to booking accommodations, tours, transportation, and
                other travel experiences. All services are provided as
                described, but we do not guarantee the availability or accuracy
                of third-party services listed on the App.
              </Text>
            </div>

            <div style={{ marginTop: 4 }}>
              <Text
                fontWeight={600}
                fontSize={16}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                4. User Responsibilities{" "}
              </Text>
            </div>

            <div
              style={{
                textAlign: "justify",
                paddingRight: "1%",
                lineHeight: "14px",
              }}
            >
              <Text
                fontWeight={400}
                fontSize={12}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                When using the App, you agree to:{" "}
              </Text>
            </div>

            <div
              style={{
                textAlign: "justify",
                paddingRight: "1%",
                lineHeight: "14px",
              }}
            >
              <Text
                fontWeight={400}
                fontSize={12}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                Provide accurate and up-to-date information during registration
                and bookings.{" "}
              </Text>
            </div>

            <div
              style={{
                textAlign: "justify",
                paddingRight: "1%",
                lineHeight: "14px",
              }}
            >
              <Text
                fontWeight={400}
                fontSize={12}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                Use the App solely for personal, non-commercial purposes unless
                otherwise agreed upon.{" "}
              </Text>
            </div>

            <div
              style={{
                textAlign: "justify",
                paddingRight: "1%",
                lineHeight: "14px",
              }}
            >
              <Text
                fontWeight={400}
                fontSize={12}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                Comply with all applicable laws, regulations, and the terms
                outlined in this Agreement.{" "}
              </Text>
            </div>

            <div
              style={{
                textAlign: "justify",
                paddingRight: "1%",
                lineHeight: "14px",
              }}
            >
              <Text
                fontWeight={400}
                fontSize={12}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                Not engage in any unlawful or fraudulent activities while using
                the App.{" "}
              </Text>
            </div>

            <div style={{ marginTop: 4 }}>
              <Text
                fontWeight={600}
                fontSize={16}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                5. Booking and Payment Terms{" "}
              </Text>
            </div>

            <div
              style={{
                textAlign: "justify",
                paddingRight: "1%",
                lineHeight: "14px",
              }}
            >
              <Text
                fontWeight={400}
                fontSize={12}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                Users can make travel bookings through the App. All bookings are
                subject to availability and the policies of third-party service
                providers.{" "}
              </Text>
            </div>

            <div
              style={{
                textAlign: "justify",
                paddingRight: "1%",
                lineHeight: "14px",
              }}
            >
              <Text
                fontWeight={400}
                fontSize={12}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                Payments for bookings must be made using the payment methods
                supported by the App. Payment terms (e.g., advance payments,
                payment deadlines) are governed by the third-party provider’s
                policies.{" "}
              </Text>
            </div>

            <div
              style={{
                textAlign: "justify",
                paddingRight: "1%",
                lineHeight: "14px",
              }}
            >
              <Text
                fontWeight={400}
                fontSize={12}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                The prices listed on the App are subject to change and may
                include applicable taxes and fees.{" "}
              </Text>
            </div>

            <div style={{ marginTop: 4 }}>
              <Text
                fontWeight={600}
                fontSize={16}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                6. Cancellations and Refunds{" "}
              </Text>
            </div>

            <div
              style={{
                textAlign: "justify",
                paddingRight: "1%",
                lineHeight: "14px",
              }}
            >
              <Text
                fontWeight={400}
                fontSize={12}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                Cancellations and refund requests are subject to the
                cancellation policies of third-party service providers (e.g.,
                hotels, tour operators). Cancellation and refund policies
                applicable to specific services and packages are mentioned on
                our App. Please refer to these policies before booking.{" "}
              </Text>
            </div>

            <div
              style={{
                textAlign: "justify",
                paddingRight: "1%",
                lineHeight: "14px",
              }}
            >
              <Text
                fontWeight={400}
                fontSize={12}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                Wherever applicable, refunds will be processed by Adventra
                within 5-7 working days{" "}
              </Text>
            </div>

            <div style={{ marginTop: 4 }}>
              <Text
                fontWeight={600}
                fontSize={16}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                7. User Conduct{" "}
              </Text>
            </div>

            <div
              style={{
                textAlign: "justify",
                paddingRight: "1%",
                lineHeight: "14px",
              }}
            >
              <Text
                fontWeight={400}
                fontSize={12}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                You agree not to use the App to post, upload, or share content
                that is unlawful, harmful, defamatory, or violates third-party
                rights.{" "}
              </Text>
            </div>

            <div
              style={{
                textAlign: "justify",
                paddingRight: "1%",
                lineHeight: "14px",
              }}
            >
              <Text
                fontWeight={400}
                fontSize={12}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                Any unauthorized use of the App, including the exploitation of
                services for financial gain, is strictly prohibited.{" "}
              </Text>
            </div>

            <div style={{ marginTop: 4 }}>
              <Text
                fontWeight={600}
                fontSize={16}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                8. Intellectual Property{" "}
              </Text>
            </div>

            <div
              style={{
                textAlign: "justify",
                paddingRight: "1%",
                lineHeight: "14px",
              }}
            >
              <Text
                fontWeight={400}
                fontSize={12}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                All content, trademarks, logos, and other intellectual property
                displayed on the App are owned by Adventra or its licensors.{" "}
              </Text>
            </div>

            <div
              style={{
                textAlign: "justify",
                paddingRight: "1%",
                lineHeight: "14px",
              }}
            >
              <Text
                fontWeight={400}
                fontSize={12}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                You are granted a limited, non-exclusive, non-transferable right
                to use the App for personal purposes. You may not copy,
                distribute, or reverse-engineer any part of the App without our
                express permission.{" "}
              </Text>
            </div>

            <div style={{ marginTop: 4 }}>
              <Text
                fontWeight={600}
                fontSize={16}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                9. Limitation of Liability{" "}
              </Text>
            </div>

            <div
              style={{
                textAlign: "justify",
                paddingRight: "1%",
                lineHeight: "14px",
              }}
            >
              <Text
                fontWeight={400}
                fontSize={12}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                The App and services are provided on an "as-is" and
                "as-available" basis. We do not warrant the completeness,
                reliability, or accuracy of any information or services offered.{" "}
              </Text>
            </div>

            <div
              style={{
                textAlign: "justify",
                paddingRight: "1%",
                lineHeight: "14px",
              }}
            >
              <Text
                fontWeight={400}
                fontSize={12}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                Adventra Technologies Private Limited is not responsible for any
                loss, injury, or damage resulting from your use of the App or
                any third-party services booked through the App.{" "}
              </Text>
            </div>

            <div
              style={{
                textAlign: "justify",
                paddingRight: "1%",
                lineHeight: "14px",
              }}
            >
              <Text
                fontWeight={400}
                fontSize={12}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                To the extent permitted by law, our liability is limited to the
                amount paid by you for the specific booking or service in
                dispute.{" "}
              </Text>
            </div>

            <div style={{ marginTop: 4 }}>
              <Text
                fontWeight={600}
                fontSize={16}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                10. Indemnification{" "}
              </Text>
            </div>

            <div
              style={{
                textAlign: "justify",
                paddingRight: "1%",
                lineHeight: "14px",
              }}
            >
              <Text
                fontWeight={400}
                fontSize={12}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                You agree to indemnify and hold harmless Adventra Technologies
                Private Limited, its officers, employees, and agents from and
                against any claims, damages, liabilities, and expenses arising
                out of your use of the App, violation of this Agreement, or
                infringement of any third-party rights.{" "}
              </Text>
            </div>

            <div style={{ marginTop: 4 }}>
              <Text
                fontWeight={600}
                fontSize={16}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                11. Termination{" "}
              </Text>
            </div>

            <div
              style={{
                textAlign: "justify",
                paddingRight: "1%",
                lineHeight: "14px",
              }}
            >
              <Text
                fontWeight={400}
                fontSize={12}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                We reserve the right to terminate your access to the App at any
                time, for any reason, including but not limited to violations of
                this Agreement or misuse of the App.{" "}
              </Text>
            </div>

            <div style={{ marginTop: 4 }}>
              <Text
                fontWeight={600}
                fontSize={16}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                12. Dispute Resolution{" "}
              </Text>
            </div>

            <div
              style={{
                textAlign: "justify",
                paddingRight: "1%",
                lineHeight: "14px",
              }}
            >
              <Text
                fontWeight={400}
                fontSize={12}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                Any disputes arising from or relating to this Agreement will be
                resolved through arbitration in accordance with the laws of
                Karnataka{" "}
              </Text>
            </div>

            <div
              style={{
                textAlign: "justify",
                paddingRight: "1%",
                lineHeight: "14px",
              }}
            >
              <Text
                fontWeight={400}
                fontSize={12}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                Both parties agree to attempt to resolve any disputes through
                negotiation prior to initiating legal action.{" "}
              </Text>
            </div>

            <div style={{ marginTop: 4 }}>
              <Text
                fontWeight={600}
                fontSize={16}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                13. Changes to the Agreement{" "}
              </Text>
            </div>

            <div
              style={{
                textAlign: "justify",
                paddingRight: "1%",
                lineHeight: "14px",
              }}
            >
              <Text
                fontWeight={400}
                fontSize={12}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                We reserve the right to modify this Agreement at any time.
                Changes will be effective upon posting on the App. Your
                continued use of the App after such changes signifies your
                acceptance of the updated terms.
              </Text>
            </div>

            <div style={{ marginTop: 4 }}>
              <Text
                fontWeight={600}
                fontSize={16}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                14. Privacy{" "}
              </Text>
            </div>

            <div
              style={{
                textAlign: "justify",
                paddingRight: "1%",
                lineHeight: "14px",
              }}
            >
              <Text
                fontWeight={400}
                fontSize={12}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                Your privacy is important to us. Please refer to our Privacy
                Policy for details on how we collect, use, and protect your
                personal information.{" "}
              </Text>
            </div>

            <div style={{ marginTop: 4 }}>
              <Text
                fontWeight={600}
                fontSize={16}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                15. Contact Us
              </Text>
            </div>

            <div
              style={{
                textAlign: "justify",
                paddingRight: "1%",
                lineHeight: "14px",
              }}
            >
              <Text
                fontWeight={400}
                fontSize={12}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                If you have any questions or concerns about this Agreement,
                please contact us at: Adventra Technologies Private Limited -
                adventra24@gmail.com{" "}
              </Text>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default TermAndCondition;
