import React from "react";
import Text from "../../components/TextSection/Text";
import { Col, Row } from "reactstrap";

function PrivacyAndPolicy() {
  return (
    <div>
      <div style={{ padding: "10px", lineHeight: "1.6" }}>
        <Row nogutters>
          <Col
            xs={12}
            md={12}
            style={{
              textAlign: "left",
              marginTop: "0%",
              paddingRight: "20px",
            }}
          >
            <div style={{ marginTop: 4 }}>
              <Text
                fontWeight={600}
                fontSize={20}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                Privacy Policy
              </Text>
            </div> <div
              style={{
                textAlign: "justify",
                paddingRight: "1%",
                lineHeight: "14px",
              }}
            >
              <Text
                fontWeight={400}
                fontSize={12}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                Effective Date: 1st September 2024
              </Text>
            </div>
            <div
              style={{
                textAlign: "justify",
                paddingRight: "1%",
                lineHeight: "14px",
              }}
            >
              <Text
                fontWeight={400}
                fontSize={12}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                Adventra Technologies Private Limited is committed to protecting
                your privacy and ensuring the security of your personal data.
                This Privacy Policy explains how we collect, use, share, and
                protect your information when you use our app.
              </Text>
            </div>
            <div style={{ marginTop: 4 }}>
              <Text
                fontWeight={600}
                fontSize={16}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                1. Information We Collect
              </Text>
            </div>
            <div
              style={{
                textAlign: "justify",
                paddingRight: "1%",
                lineHeight: "14px",
              }}
            >
              <Text
                fontWeight={400}
                fontSize={12}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                We collect various types of information, including:
              </Text>
            </div>

            <div
              style={{
                textAlign: "justify",
                paddingRight: "1%",
                lineHeight: "14px",
              }}
            >
              <Text
                fontWeight={400}
                fontSize={12}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                Personal Information: When you create an account, book a trip,
                or interact with the app, we may collect personal details such
                as your name, email address, phone number, billing information,
                and passport/ID details (if required for bookings).{" "}
              </Text>
            </div>

            <div
              style={{
                textAlign: "justify",
                paddingRight: "1%",
                lineHeight: "14px",
              }}
            >
              <Text
                fontWeight={400}
                fontSize={12}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                Usage Information: We collect data on how you interact with the
                app, such as your search history, preferences, and device
                information (IP address, operating system, browser type).{" "}
              </Text>
            </div>

            <div
              style={{
                textAlign: "justify",
                paddingRight: "1%",
                lineHeight: "14px",
              }}
            >
              <Text
                fontWeight={400}
                fontSize={12}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                Location Information: With your permission, we may collect and
                process your device's location to provide relevant travel
                suggestions or services near you.{" "}
              </Text>
            </div>

            <div
              style={{
                textAlign: "justify",
                paddingRight: "1%",
                lineHeight: "14px",
              }}
            >
              <Text
                fontWeight={400}
                fontSize={12}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                Third-Party Data: If you link your account to third-party
                services (e.g., social media logins or payment processors), we
                may receive information from those services.
              </Text>
            </div>

            <div style={{ marginTop: 4 }}>
              <Text
                fontWeight={600}
                fontSize={16}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                2. How We Use Your Information
              </Text>
            </div>

            <div
              style={{
                textAlign: "justify",
                paddingRight: "1%",
                lineHeight: "14px",
              }}
            >
              <Text
                fontWeight={400}
                fontSize={12}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                We use your information to:
              </Text>
            </div>

            <div
              style={{
                textAlign: "justify",
                paddingRight: "1%",
                lineHeight: "14px",
              }}
            >
              <Text
                fontWeight={400}
                fontSize={12}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                Provide Services: Facilitate bookings, process payments, and
                offer customer support.
              </Text>
            </div>

            <div
              style={{
                textAlign: "justify",
                paddingRight: "1%",
                lineHeight: "14px",
              }}
            >
              <Text
                fontWeight={400}
                fontSize={12}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                Personalize Experience: Recommend trips, destinations, and
                services tailored to your preferences and location.{" "}
              </Text>
            </div>
            <div
              style={{
                textAlign: "justify",
                paddingRight: "1%",
                lineHeight: "14px",
              }}
            >
              <Text
                fontWeight={400}
                fontSize={12}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                Improve the App: Analyze usage patterns and feedback to enhance
                the app’s functionality and features.{" "}
              </Text>
            </div>
            <div
              style={{
                textAlign: "justify",
                paddingRight: "1%",
                lineHeight: "14px",
              }}
            >
              <Text
                fontWeight={400}
                fontSize={12}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                Communicate with You: Send booking confirmations, travel
                updates, promotional offers, and other relevant communications
                (based on your notification preferences).{" "}
              </Text>
            </div>
            <div
              style={{
                textAlign: "justify",
                paddingRight: "1%",
                lineHeight: "14px",
              }}
            >
              <Text
                fontWeight={400}
                fontSize={12}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                Legal Compliance: Comply with legal obligations or protect our
                rights, such as fraud prevention and risk management.{" "}
              </Text>
            </div>

            <div style={{ marginTop: 8 }}>
              <Text
                fontWeight={600}
                fontSize={16}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                3. Sharing Your Information
              </Text>
            </div>

            <div
              style={{
                textAlign: "justify",
                paddingRight: "1%",
                lineHeight: "14px",
              }}
            >
              <Text
                fontWeight={400}
                fontSize={12}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                We may share your information in the following situations:
              </Text>
            </div>

            <div
              style={{
                textAlign: "justify",
                paddingRight: "1%",
                lineHeight: "14px",
              }}
            >
              <Text
                fontWeight={400}
                fontSize={12}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                Third-Party Providers: To fulfill bookings, we share necessary
                personal information with service providers such as airlines,
                hotels, tour operators, and payment processors.
              </Text>
            </div>

            <div
              style={{
                textAlign: "justify",
                paddingRight: "1%",
                lineHeight: "14px",
              }}
            >
              <Text
                fontWeight={400}
                fontSize={12}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                Business Partners: In the event of a merger, acquisition, or
                sale, we may transfer your information to the new entity,
                provided they maintain the same level of data protection.{" "}
              </Text>
            </div>

            <div
              style={{
                textAlign: "justify",
                paddingRight: "1%",
                lineHeight: "14px",
              }}
            >
              <Text
                fontWeight={400}
                fontSize={12}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                Legal Requirements: If required by law, or in response to legal
                requests (subpoenas, court orders), we may disclose your
                information to comply with legal processes.{" "}
              </Text>
            </div>

            <div
              style={{
                textAlign: "justify",
                paddingRight: "1%",
                lineHeight: "14px",
              }}
            >
              <Text
                fontWeight={400}
                fontSize={12}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                With Your Consent: If you opt in to share data with third
                parties (e.g., for tailored offers), we will share your
                information as you permit.{" "}
              </Text>
            </div>

            <div style={{ marginTop: 8 }}>
              <Text
                fontWeight={600}
                fontSize={16}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                4. Your Privacy Settings
              </Text>
            </div>

            <div
              style={{
                textAlign: "justify",
                paddingRight: "1%",
                lineHeight: "14px",
              }}
            >
              <Text
                fontWeight={400}
                fontSize={12}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                You control your privacy settings through the app, including:
              </Text>
            </div>

            <div
              style={{
                textAlign: "justify",
                paddingRight: "1%",
                lineHeight: "14px",
              }}
            >
              <Text
                fontWeight={400}
                fontSize={12}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                Account Settings: You can update your personal details, change
                your password, and view your booking history.{" "}
              </Text>
            </div>

            <div
              style={{
                textAlign: "justify",
                paddingRight: "1%",
                lineHeight: "14px",
              }}
            >
              <Text
                fontWeight={400}
                fontSize={12}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                Notification Preferences: Manage your preferences for receiving
                emails, push notifications, and SMS alerts regarding bookings or
                promotions.{" "}
              </Text>
            </div>

            <div
              style={{
                textAlign: "justify",
                paddingRight: "1%",
                lineHeight: "14px",
              }}
            >
              <Text
                fontWeight={400}
                fontSize={12}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                Location Services: You can enable or disable location tracking
                within your device’s settings.{" "}
              </Text>
            </div>

            <div
              style={{
                textAlign: "justify",
                paddingRight: "1%",
                lineHeight: "14px",
              }}
            >
              <Text
                fontWeight={400}
                fontSize={12}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                Data Sharing: You can choose to opt out of data sharing for
                marketing purposes through your app settings.{" "}
              </Text>
            </div>

            <div style={{ marginTop: 8 }}>
              <Text
                fontWeight={600}
                fontSize={16}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                5. Data Security
              </Text>
            </div>

            <div
              style={{
                textAlign: "justify",
                paddingRight: "1%",
                lineHeight: "14px",
              }}
            >
              <Text
                fontWeight={400}
                fontSize={12}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                We employ industry-standard security measures to protect your
                personal information, including encryption, firewalls, and
                secure payment gateways. However, no online service is 100%
                secure, and we cannot guarantee the absolute security of your
                data.
              </Text>
            </div>

            <div style={{ marginTop: 8 }}>
              <Text
                fontWeight={600}
                fontSize={16}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                6. Data Retention
              </Text>
            </div>

            <div
              style={{
                textAlign: "justify",
                paddingRight: "1%",
                lineHeight: "14px",
              }}
            >
              <Text
                fontWeight={400}
                fontSize={12}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                We retain your information only as long as necessary to fulfill
                the purposes outlined in this policy or as required by law. When
                no longer needed, your data will be securely deleted or
                anonymized.{" "}
              </Text>
            </div>

            <div style={{ marginTop: 8 }}>
              <Text
                fontWeight={600}
                fontSize={16}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                7. Your Rights{" "}
              </Text>
            </div>

            <div
              style={{
                textAlign: "justify",
                paddingRight: "1%",
                lineHeight: "14px",
              }}
            >
              <Text
                fontWeight={400}
                fontSize={12}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                Depending on your location, you may have the following rights
                regarding your data:{" "}
              </Text>
            </div>
            <div
              style={{
                textAlign: "justify",
                paddingRight: "1%",
                lineHeight: "14px",
              }}
            >
              <Text
                fontWeight={400}
                fontSize={12}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                Access and Correction: You can access or update your personal
                information directly through your account settings.{" "}
              </Text>
            </div>
            <div
              style={{
                textAlign: "justify",
                paddingRight: "1%",
                lineHeight: "14px",
              }}
            >
              <Text
                fontWeight={400}
                fontSize={12}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                Data Deletion: You can request the deletion of your personal
                information unless we are required to retain it by law.{" "}
              </Text>
            </div>
            <div
              style={{
                textAlign: "justify",
                paddingRight: "1%",
                lineHeight: "14px",
              }}
            >
              <Text
                fontWeight={400}
                fontSize={12}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                Opt-Out: You may opt out of marketing communications at any time
                by adjusting your preferences in the app or by following the
                unsubscribe instructions in promotional emails.{" "}
              </Text>
            </div>
           

            <div style={{ marginTop: 8 }}>
              <Text
                fontWeight={600}
                fontSize={16}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                8. Changes to this Privacy Policy
              </Text>
            </div>

            <div
              style={{
                textAlign: "justify",
                paddingRight: "1%",
                lineHeight: "14px",
              }}
            >
              <Text
                fontWeight={400}
                fontSize={12}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                We may update this Privacy Policy from time to time to reflect
                changes in our practices or legal requirements. When we make
                significant changes, we will notify you via the app or email.
                Continued use of the app after such changes constitutes
                acceptance of the updated policy.{" "}
              </Text>
            </div>

            <div style={{ marginTop: 8 }}>
              <Text
                fontWeight={600}
                fontSize={16}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                9. Contact Us{" "}
              </Text>
            </div>

            <div
              style={{
                textAlign: "justify",
                paddingRight: "1%",
                lineHeight: "14px",
              }}
            >
              <Text
                fontWeight={400}
                fontSize={12}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                If you have any questions or concerns about this Privacy Policy,
                or wish to exercise your data rights, please contact us at:{" "}
              </Text>
            </div>

            <div
              style={{
                textAlign: "justify",
                paddingRight: "1%",
                lineHeight: "14px",
              }}
            >
              <Text
                fontWeight={400}
                fontSize={12}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                Adventra Technologies Private Limited{" "}
              </Text>
            </div>

            <div
              style={{
                textAlign: "justify",
                paddingRight: "1%",
                lineHeight: "14px",
              }}
            >
              <Text
                fontWeight={400}
                fontSize={12}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                Email: adventra24@gmail.com{" "}
              </Text>
            </div>

            <div
              style={{
                textAlign: "justify",
                paddingRight: "1%",
                lineHeight: "14px",
              }}
            >
              <Text
                fontWeight={400}
                fontSize={12}
                fontFamily="Inter"
                color="#1E1E1E"
              >
                Address: Adventra Technologies Private Limited, Flat no. 102,
                Pride Regalia Apartments, Hulimavu, Bannerghatta Road, Bangalore
                - 560076{" "}
              </Text>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default PrivacyAndPolicy;
