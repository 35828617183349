import BackgroundSection from "../components/Navbar/BackgroundSection/BackgroundSection";
import Text from "../components/TextSection/Text";
import header from '../assets/images/header.jpg'
import SuccessModal from "../components/Modals/SuccessModal";

const HeaderSection = ()=>{
    const containerStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        //height: '100vh',  // Full viewport height to center vertically
        margin: 0,  // Remove default margin
        padding: 0,  // Remove default padding
        marginTop: 16,
        marginLeft: '2%',
        marginRight: '2%',
      };

      const scrollToSection = () => {
        const element = document.getElementById('bookingform');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      };
    
    return(
        <>
         <div style={containerStyle}>
        <BackgroundSection
          imageUrl={header}
          // width="1214px"
          height="543px"
          borderRadius="20px"
        >
          <div style={{ marginLeft: '12%', marginRight: '12%' }}>
            <Text
              fontWeight={700}
              fontSize={48}
              fontFamily="Inter"
              color="#FFFFFF"
            >🏃‍♂️ Limited Accommodation Available for Malnad Ultra Run on Nov 23rd! 🏃‍♀</Text>
          </div>
          <div style={{ marginLeft: '12%', marginRight: '12%' }}>
            <Text
              fontWeight={400}
              fontSize={20}
              fontFamily="Inter"
              color="#FFFFFF"
            >
              Don’t miss out on staying right at the heart of the action! Book your spot now and be part of this incredible experience. 🏅
            </Text>
          </div>

          {/* <button className="button" onClick={()=>scrollToSection()} style={{marginTop:20}}>Book now</button> */}
        </BackgroundSection>
      </div>
     
        </>
    )
}

export default HeaderSection;