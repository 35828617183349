import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

// Styled components for the carousel
const CarouselContainer = styled.div`
  width: 80%;
  margin: auto;
  position: relative;
`;

const LargeImage = styled.img`
  width: 100%;
  height: 400px; /* Set a fixed height */
  object-fit: cover; /* Ensure the image covers the area while maintaining aspect ratio */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

const ThumbnailsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

const Thumbnail = styled.img`
  width: 80px;
  height: 60px;
  object-fit: cover;
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: 4px;
  margin: 0 5px;
  transition: border-color 0.3s;

  &:hover {
    border-color: #333;
  }
`;

const ThumbnailActive = styled(Thumbnail)`
  border-color: #007bff;
`;

function Carousel2({ images, slideInterval = 3000 }) {
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setSelectedIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, slideInterval);

    return () => clearInterval(interval); // Clear interval on component unmount
  }, [images.length, slideInterval]);

  const handleThumbnailClick = (index) => {
    setSelectedIndex(index);
  };

  return (
    <CarouselContainer>
      <LargeImage src={images[selectedIndex]} alt={`Image ${selectedIndex}`} />
      <ThumbnailsContainer>
        {images.map((image, index) => (
          <Thumbnail
            key={index}
            src={image}
            alt={`Thumbnail ${index}`}
            onClick={() => handleThumbnailClick(index)}
            className={index === selectedIndex ? 'active' : ''}
          />
        ))}
      </ThumbnailsContainer>
    </CarouselContainer>
  );
}

export default Carousel2;
